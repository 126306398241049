/* Resetting default margin and padding for all elements */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

/* Ensures the entire app fills the viewport and centers content */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Styling for the header, reducing space and adjusting text size */
.header {
  width: 100%;
  text-align: center;
  background-color: white;
  color: rgb(94, 90, 90);
  padding: 10px 0;
  box-sizing: border-box;
}

.header h1 {
  margin: 0;
  font-size: 24px; /* Larger text for the main title */
}

.header p {
  margin: 5px 0;
  font-size: 16px; /* Smaller text for subheading */
}

/* Setting up the grid to display four columns */
.game {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  /* Aligns children starting from the top */
  height: auto;  /* Remove any min-height or height that might be stretching the container */
  padding: 10px;
  box-sizing: border-box;
}


.board {

  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Ensures four columns */
  gap: 10px; /* Space between tiles */
  padding: 5px; /* Padding around the entire grid */
  box-sizing: border-box;
  overflow: hidden;
  align-content: start;
 
}

.completed-group {
  background-color: #e0e0e0;  /* Distinct background to highlight correct answers */
  color: white;
  padding: 10px;
  margin: 10px 0;  /* Adds space around each group for clarity */
  border-radius: 5px;  /* Softens the edges */
  box-sizing: border-box;
  width: 100%;  /* Ensures it fits within the container */
  display:flex;
  flex-direction:row;  /* Stacks group members vertically */
  align-items: center;  /* Centers the content */
  justify-content: center;
}

.completed-reason {
  margin-bottom: 5px;  /* Space between the reason and the list of members */
  font-weight: bold;  /* Emphasizes the reason */
}

/* Styling for individual tiles */
.tile {
  background-color: #FFC0D9;
  border-color: #ffc0d9;
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensures tiles selected have a different background */
.tile.selected {
  background-color: #FF90BC;
  border-color: #FF90BC;
}

/* Styling for the bottom button section */
.buttons {
  width: 100%;  /* Full width to align with the container */
  padding: 10px 0;  /* Padding for spacing */
  box-sizing: border-box;  /* Includes padding in width calculation */
  display: flex;
  justify-content: space-around;  /* Distributes buttons evenly */
  background: white;  /* Optional: Sets a background color for visibility */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  /* Adds subtle shadow for depth */
  margin-top: 20px;  /* Adds space between the mistakes section and buttons */
}

.buttons button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Styling for the mistakes remaining section */
.mistakes-remaining {
  width: 100%; /* Ensures it takes the full width of its container */
  text-align: center; /* Centers the text */
  padding: 10px 0; /* Adds padding above and below for spacing */
  margin-top: 10px;
  box-sizing: border-box;
}

/* Responsive adjustments for smaller screens */
/* Responsive adjustments */
@media (max-width: 600px) {


  .tile {
      padding: 5px;  /* Smaller padding */
      font-size: 0.8em;  /* Smaller font size if necessary */
  }
}
