/* Resetting default margin and padding for all elements */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Ensures the entire app fills the viewport and centers content */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

/* Styling for the header, reducing space and adjusting text size */
.header {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
}

.header h1 {
  margin: 0;
  font-size: 24px; /* Larger text for the main title */
}

.p {
  margin: 5px 0;
  font-size: 16px; /* Smaller text for subheading */
  text-align: center;
}

/* Setting up the grid to display four columns */
.game {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the board */
  justify-content: flex-start;  /* Aligns children starting from the top */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.board {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Ensures four columns */
  gap: 10px; /* Space between tiles */
  width: 100%;
  max-width: 600px; /* Increased maximum width of the board */
  padding: 5px; /* Padding around the entire grid */
  box-sizing: border-box;
}

/* Styling for individual tiles */
.tile {
  background-color: #FFC0D9;
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  height: 85px; /* Increased fixed height for the tile */
  width:95px; /* Increased fixed width for the tile */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em; /* Base font size */
}

/* Ensures tiles selected have a different background */
.tile.selected {
  background-color: #FF90BC;
}

/* Styling for the mistakes remaining section */
.mistakes-remaining {
  width: 100%; /* Ensures it takes the full width of its container */
  text-align: center; /* Centers the text */
  padding: 10px 0; /* Adds padding above and below for spacing */
  margin-top: 10px;
  box-sizing: border-box;
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Center the images horizontally */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between text and images */
}

.mistakes-remaining img {
  width: 35px; /* Adjust size of Andy Cohen images */
  height: 35px; /* Adjust size of Andy Cohen images */
  margin: 0 2px; /* Reduce margin between images */
  display: inline-block; /* Display images inline */
}

/* Styling for the bottom button section */
.buttons {
  width: 100%;  /* Full width to align with the container */
  padding: 10px 0;  /* Padding for spacing */
  box-sizing: border-box;  /* Includes padding in width calculation */
  display: flex;
  justify-content: space-around;  /* Distributes buttons evenly */
  background: white;  /* Optional: Sets a background color for visibility */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);  /* Adds subtle shadow for depth */
  margin-top: 20px;  /* Adds space between the mistakes section and buttons */
}

.buttons button {
  flex: 1; /* Allow buttons to grow and shrink */
  margin: 5px;
  padding: 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 100px; /* Maximum width for buttons */
  font-size: 0.9em; /* Adjust font size */
}

/* Styling for completed groups */
.completed-groups {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px; /* Adjust margin */
}

.completed-group {
  background-color: #7d61ba;
  color: white;
  padding: 10px; /* Adjust padding */
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%; /* Take full width of the container */
  display: flex;
  flex-direction: column; /* Stacks group members vertically */
  align-items: center; /* Centers the content */
  justify-content: center;
}

.completed-reason {
  font-size: 1.2em; /* Larger font size */
  font-weight: bold; /* Bold text */
  margin-bottom: 5px; /* Space between the reason and the list of members */
  text-align: center; /* Center the text */
}

.completed-members {
  font-size: 1em; /* Font size for members */
  text-align: center; /* Center the text */
  margin-top: 5px;
}

/* Styling for notifications */
.notification {
  background-color: #ffdd57; /* Yellow for loss */
  color: #333;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.notification1 {
  background-color: #9fe1b0; /* Green for win */
  color: #333;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 800px) {
  .board,
  .mistakes-remaining,
  .buttons,
  .completed-groups,
  .notification,
  .notification1 {
    max-width: 350px; /* Reduce max width for smaller screens */
  }

  .tile,
  .buttons button,
  .mistakes-remaining img,
  .completed-tile {
    height: 75px; /* Adjust tile height for smaller screens */
    width: 75px; /* Adjust tile width for smaller screens */
    font-size: 0.9em; /* Adjust font size if necessary */
  }

  .mistakes-remaining {
    font-size: 0.9em; /* Adjust font size */
    padding: 8px 0; /* Adjust padding */
  }

  .mistakes-remaining img {
    width: 30px; /* Adjust size of Andy Cohen images */
    height: 30px; /* Adjust size of Andy Cohen images */
  }

  .buttons button {
    padding: 8px; /* Smaller padding for buttons */
    font-size: 0.9em; /* Adjust font size */
  }

  .completed-group {
    padding: 8px;
  }

  .completed-reason {
    font-size: 0.9em;
    margin-bottom: 5px; /* Add some space between reason and tiles */
  }

  .completed-tile {
    max-width: 100%;
    font-size: 0.8em;
  }
}

@media (max-width: 600px) {
  .header h1 {
    font-size: 20px; /* Smaller title font size for mobile */
  }

  .p {
    font-size: 14px; /* Smaller font size for mobile */
  }

  .board,
  .mistakes-remaining,
  .buttons,
  .completed-groups,
  .notification,
  .notification1 {
    max-width: 400px; /* Reduce max width for smaller screens */
  }

  .tile,
  .buttons button,
  .mistakes-remaining img,
  .completed-tile {
    height: 65px; /* Further adjust tile height */
    width: 65px; /* Further adjust tile width */
    font-size: 0.8em; /* Smaller font size if necessary */
  }

  .mistakes-remaining {
    font-size: 0.8em; /* Adjust font size */
    padding: 6px 0; /* Adjust padding */
  }

  .mistakes-remaining img {
    width: 25px; /* Adjust size of Andy Cohen images */
    height: 25px; /* Adjust size of Andy Cohen images */
    margin: 0 1px; /* Adjust margin between images */
  }
  .button-wrapper {
    width: 100%;
    max-width: 500px; /* Match the max-width of the board */
    margin: 0 auto; /* Center the button wrapper */
  }

  .buttons {
    display: flex;
    flex-direction: row; /* Ensure buttons are in a row */
    justify-content: space-around; /* Distribute buttons evenly */
    flex-wrap: nowrap; /* Prevent buttons from wrapping */
    width: 100%; /* Take full width of the wrapper */
  
  }

  .buttons button {
    margin: 5px; /* Space between buttons */
    width: auto; /* Adjust button width */
    flex: 1 1 20%; /* Allow buttons to grow and shrink */
    padding: 6px; /* Smaller padding for buttons */
    max-width: 80px; /* Maximum width for buttons */
    font-size: 0.8em; /* Adjust font size */
  }

  .completed-group {
    padding: 6px;
  }

  .completed-reason {
    font-size: 0.8em;
    margin-bottom: 5px; /* Add some space between reason and tiles */
  }

  .completed-tile {
    max-width: 100%;
    font-size: 0.7em;
  }
}